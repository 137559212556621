import styles from './Slider.module.scss';

import type typeSlider from '@/utilities/types/Slider';
import Button from '@/components/atoms/Button';

import SliderCaption from './SliderCaption';

interface SliderPaginationProps {
  slides: typeSlider['slides'];
  activeSlide: number;
  setActiveSlide: (index: number) => any;
}

const SliderPagination = (props: SliderPaginationProps) => {
  const { slides, activeSlide, setActiveSlide } = props;

  return (
    <div className={styles.pagination}>
      {slides.length > 1 && (
        <div className={styles.paginationInner}>
          {slides.map((slide, index: number) => (
            <Button
              key={index}
              className={`${styles.pill} ${
                activeSlide === index && `${styles.current}`
              }`}
              onClick={() => {
                setActiveSlide(index);
              }}
              aria={`Slide ${index + 1}`}
            >
              <span></span>
            </Button>
          ))}
        </div>
      )}
      <SliderCaption caption={slides[activeSlide].caption} />
    </div>
  );
};

export default SliderPagination;
